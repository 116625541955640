@import "../../themes/generated/variables.base.scss";

.login-header {
  text-align: center;
  margin-bottom: 40px;

  .title {
    color: $base-accent;
    font-weight: bold;
    font-size: 30px;
    margin: 0;
  }

  #small-indicator,
  #medium-indicator,
  #large-indicator {
    vertical-align: middle;
    margin-right: 10px;
  }

  #loginButton,
  #loginButton .dx-button-content {
    padding: 0;
  }

  #loginButton .button-indicator {
    height: 32px;
    width: 32px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
}
